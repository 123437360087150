const {
  default: ApolloClientManager,
} = require("@/Logic/GraphQL/ApolloClientManager");
const {
  CREATE_COURSE_ORDER,
  VERIFY_PAYMENT,
} = require("@/Logic/GraphQL/Mutations/payment.mutation");

class BasePaymentController {
  constructor() {}
  async createOrder(
    courseSlug,
    paymentType,
    billingCountry,
    withEMI,
    anonymousUserId,
    email,
    selectedPlan
  ) {
    const data = await ApolloClientManager.client.mutate({
      mutation: CREATE_COURSE_ORDER,
      variables: {
        courseSlug,
        paymentType,
        billingCountry,
        withEMI,
        anonymousUserId,
        email,
        selectedPlan,
      },
    });
    return data.data.createCourseOrder;
  }

  async verifyPayment({ paymentId, orderId, signature, courseSlug }) {
    const data = await ApolloClientManager.client.mutate({
      mutation: VERIFY_PAYMENT,
      variables: {
        courseSlug,
        payment_id: paymentId,
        order_id: orderId,
        signature,
      },
    });

    return data.data.verifyPayment;
  }
}

module.exports = BasePaymentController;
