import { gql } from "@apollo/client";

const CREATE_COURSE_ORDER = gql`
  mutation CreateCourseOrder(
    $courseSlug: String!
    $paymentType: String!
    $billingCountry: String!
    $withEMI: Boolean!
    $anonymousUserId: String
    $email: String
    $selectedPlan: String
  ) {
    createCourseOrder(
      courseSlug: $courseSlug
      paymentType: $paymentType
      billingCountry: $billingCountry
      withEMI: $withEMI
      anonymousUserId: $anonymousUserId
      email: $email
      selectedPlan: $selectedPlan
    ) {
      user
      style
      orderId
      payment {
        paymentId
        receipt
        amount
        currency
      }
      order {
        type
        productId
      }
      subscription_details {
        plan_id
      }
      current_status {
        status
      }
      createdAt
      updatedAt
    }
  }
`;

const VERIFY_PAYMENT = gql`
  mutation VerifyPayment(
    $courseSlug: String!
    $payment_id: String!
    $order_id: String!
    $signature: String!
  ) {
    verifyPayment(
      courseSlug: $courseSlug
      payment_id: $payment_id
      order_id: $order_id
      signature: $signature
    ) {
      orderId
      style
      payment {
        paymentId
        receipt
        amount
        currency
      }
      order {
        type
        productId
      }
      subscription_details {
        plan_id
      }
      userCourseStatus
      current_status {
        status
      }
      createdAt
      updatedAt
    }
  }
`;

export { CREATE_COURSE_ORDER, VERIFY_PAYMENT };
